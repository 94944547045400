import {
    LOGIN_INPUT_MIN_LENGTH,
    PASSWORD_INPUT_MIN_LENGTH,
    PINCODE_MAX_LENGTH,
    PINCODE_MIN_LENGTH,
    TEMP_PASS_MAX_LENGTH,
} from '#/src/lib/form-controls-const';
import { PasscodeType } from '#/src/models';
import { passcodeValidationErrors } from '#/src/store/redux/passcode/constants';
import { PasscodeTypeValues } from '#/src/store/redux/passcode/types';
import { ValidationResult } from '#/src/types/interfaces';

import validationDictionary from '../validation-dictionary';

const regExp = {
    corp: {
        login: /^[A-Za-z0-9\-._@]{1,255}$/,
        password: /^[A-Za-z0-9\-:!.,_ @#$%^&]{1,40}$/,
    },
    click: {
        login: /^[A-Za-z0-9\-._@]{1,64}$/,
        additionalLoginCondition: /^[0-9]{7,8}/,
        symbolLoginCondition: /^[@]{1}/,
        password: /^[A-Za-z0-9\-.,!@#$%^*()_+;:?=[\]{}~\\/|~"`<>&]{1,64}$/,
        newPassword:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z0-9\-.,!@#$%^*()_+;:?=[\]{}~\\/|~"`<>&]{6,16}$/,
    },
    investments: {
        login: /^[A-Za-z0-9\-._@]{1,64}$/,
        password: /^[A-Za-z0-9\-.,!@#$%^*()_+;:?=[\]{}~\\/|~"`<>&]{6,20}$/,
    },
} as const;

export function validateLogin(login: string, corporate?: boolean): ValidationResult {
    const loginValue = login && login.trim() ? login : '';

    if (!loginValue) {
        return validationDictionary.REQUIRED;
    }

    if (loginValue) {
        if (loginValue.length < LOGIN_INPUT_MIN_LENGTH && !corporate) {
            return validationDictionary.LOGIN_LENGTH;
        }

        const loginConsist = corporate
            ? regExp.corp.login.test(loginValue)
            : regExp.click.login.test(loginValue);

        if (!loginConsist) {
            return validationDictionary.LOGIN_CONSIST;
        }
    }

    return null;
}

export function validatePassword(password: string, corporate?: boolean): ValidationResult {
    const passwordValue = password && password.trim() ? password : '';

    if (!passwordValue) {
        return validationDictionary.REQUIRED;
    }

    if (passwordValue) {
        if (passwordValue.length < PASSWORD_INPUT_MIN_LENGTH && !corporate) {
            return validationDictionary.PASSWORD_LENGTH;
        }

        const passwordConsist = corporate
            ? {
                  status: regExp.corp.password.test(passwordValue),
                  error: validationDictionary.CORP_PASSWORD_CONSIST,
              }
            : {
                  status: regExp.click.password.test(passwordValue),
                  error: validationDictionary.PASSWORD_CONSIST,
              };

        if (!passwordConsist.status) {
            return passwordConsist.error;
        }
    }

    return null;
}

export function newValidateLogin(login: string): ValidationResult {
    const loginValue = login && login.trim() ? login : '';

    if (!loginValue) {
        return validationDictionary.REQUIRED;
    }

    if (loginValue) {
        const loginConsist = regExp.click.login.test(loginValue);

        if (!loginConsist) {
            return validationDictionary.LOGIN_CONSIST;
        }

        const isLoginStartsWithSymbol = regExp.click.symbolLoginCondition.test(loginValue);

        if (isLoginStartsWithSymbol) {
            return validationDictionary.LOGIN_SYMBOL;
        }

        if (loginValue.length < LOGIN_INPUT_MIN_LENGTH) {
            return validationDictionary.LOGIN_LENGTH;
        }

        const isLoginWithNumbers =
            regExp.click.additionalLoginCondition.test(loginValue) && loginValue.length <= 8;

        if (isLoginWithNumbers) {
            return validationDictionary.LOGIN_NUMBERS;
        }
    }

    return null;
}

export function newValidatePassword(password: string): ValidationResult {
    const passwordValue = password && password.trim() ? password : '';

    if (!passwordValue) {
        return validationDictionary.REQUIRED;
    }

    if (passwordValue) {
        const passwordConsist = {
            status: regExp.click.newPassword.test(passwordValue),
            error: validationDictionary.PASSWORD_CONSISTS,
        };

        if (!passwordConsist.status) {
            return passwordConsist.error;
        }
    }

    return null;
}

export function investmentsValidateLogin(login: string): ValidationResult {
    const loginValue = login && login.trim() ? login : '';

    if (!loginValue) {
        return validationDictionary.REQUIRED;
    }

    return null;
}

export function investmentsValidatePassword(password: string) {
    const passwordValue = password && password.trim() ? password : '';

    if (!passwordValue) {
        return validationDictionary.REQUIRED;
    }

    return null;
}

export function validatePincode(code: string): ValidationResult {
    const codeValue = code && code.trim() ? code : '';

    if (!codeValue) {
        return validationDictionary.REQUIRED;
    }

    if (PINCODE_MIN_LENGTH > codeValue.length || codeValue.length > PINCODE_MAX_LENGTH) {
        return validationDictionary.PINCODE_LENGTH;
    }

    return null;
}

export function validationTempPasswordAlfaOnline(
    password: string,
    blur?: boolean,
): ValidationResult {
    const passwordValue = password && password.trim() ? password : '';

    if (blur && passwordValue && passwordValue.length < TEMP_PASS_MAX_LENGTH) {
        return validationDictionary.TEMP_PASSWORD;
    }
    if (!/^\d*$/.test(passwordValue)) {
        return validationDictionary.TEMP_PASSWORD_NUMBERS;
    }

    return null;
}

export function validationTempPasswordAlfaMobile(password: string): ValidationResult {
    const passwordValue = password && password.trim() ? password : '';

    if (
        (passwordValue && passwordValue.length < TEMP_PASS_MAX_LENGTH) ||
        !/^\d*$/.test(passwordValue)
    ) {
        return validationDictionary.TEMP_PASSWORD;
    }

    if (passwordValue.length === 0) {
        return validationDictionary.TEMP_PASS_REQUIRED;
    }

    return null;
}

export function validationPasscodeAO(
    passcode: string,
    passcodeType: PasscodeTypeValues | 'AlphanumericNumeric',
): ValidationResult {
    const passcodeValue = passcode && passcode.trim() ? passcode : '';

    const { minLength, maxLength, validError } = passcodeValidationErrors[passcodeType];

    if (passcodeValue && (minLength > passcodeValue.length || passcodeValue.length > maxLength)) {
        return validError;
    }

    return null;
}

export function validationPasscodeNumericOnChange(passcode: string): ValidationResult {
    const passcodeValue = passcode && passcode.trim() ? passcode : '';

    if (!/^\d*$/.test(passcodeValue)) {
        return passcodeValidationErrors[PasscodeType.Numeric].validError;
    }

    return null;
}

export function validationPasscodeАM(
    passcode: string,
    passcodeType: PasscodeTypeValues = PasscodeType.Numeric,
) {
    const passcodeValue = passcode && passcode.trim() ? passcode : '';
    const { minLength, maxLength, validError } = passcodeValidationErrors[passcodeType];

    if (!passcodeValue) {
        return validationDictionary.PASSCODE_REQUIRED;
    }

    if (minLength > passcodeValue.length || passcodeValue.length > maxLength) {
        return validError;
    }

    return null;
}

export const validateAuthorization = {
    validateLogin,
    validatePassword,
    newValidatePassword,
    newValidateLogin,
    investmentsValidateLogin,
    investmentsValidatePassword,
    validatePincode,
};
