import differenceInYears from 'date-fns/difference_in_years';
import format from 'date-fns/format';
import getMonth from 'date-fns/get_month';

import { RegistrationType } from '#/src/models';

import { FULLNAME_INPUT_MAX_LENGTH, PHONE_INPUT_MIN_LENGTH } from '../form-controls-const';
import { luhn } from '../luhn';
import validationDictionary from '../validation-dictionary';

export function validateCardNumber(cardNumber?: string): string | null {
    const cardNumberValue = cardNumber && cardNumber.trim();

    if (!cardNumberValue) {
        return validationDictionary.REQUIRED;
    }
    if (!/[0-9]{4}(\s[0-9]{4}){2}(\s[0-9]{2,4})(\s[0-9]{2})?/.test(cardNumberValue)) {
        return validationDictionary.CARD;
    }
    if (!luhn(cardNumber.replace(/(\s)*/g, ''))) {
        return validationDictionary.CARD;
    }

    return null;
}

export function validateAccountNumber(accountNumber?: string): string | null {
    const accountValue = accountNumber && accountNumber.trim();

    if (!accountValue) {
        return validationDictionary.REQUIRED;
    }
    if (!/[0-9]{4}(\s[0-9]{4}){4}/.test(accountValue)) {
        return validationDictionary.ACCOUNT;
    }

    return null;
}

export function validatePhoneNumber(phoneNumber?: string): string | null {
    const phoneValue = phoneNumber && phoneNumber.trim();

    if (!phoneValue || (phoneValue && phoneValue.length < PHONE_INPUT_MIN_LENGTH)) {
        return validationDictionary.REQUIRED;
    }

    return null;
}

export function validateFullName(fullName?: string): string | null {
    const fullNameValue = fullName && fullName.trim();
    // TODO обработать сценарий когда fullName не правдивое значение. - PASSPORT-7993
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const FullNameSpaceCheck = fullNameValue.split(' ');
    const fullNameJoined = FullNameSpaceCheck.join('');

    if (!fullNameValue || FullNameSpaceCheck.length < 2) {
        return validationDictionary.FULLNAME;
    }
    if (!/^[а-яА-ЯёЁa-zA-Z0-9'-]+$/.test(fullNameJoined)) {
        return validationDictionary.FULLNAME_INVALID_SYMBOLS;
    }
    // TODO уточнить какая должна быть логика т.к. тут возможен баг. - PASSPORT-7993
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (fullNameJoined > FULLNAME_INPUT_MAX_LENGTH) {
        return validationDictionary.FULLNAME_LIMIT;
    }

    return null;
}

export function validateBirthDate(birthDate?: string): string | null {
    const birthDateValue = (birthDate || '').split('/');
    const currentYear = format(new Date(), 'YYYY');
    const currentDate = `${birthDateValue[2]}-${birthDateValue[1]}-${birthDateValue[0]}`;
    const currentAge = differenceInYears(new Date().getTime(), new Date(currentDate));

    if (!birthDateValue) {
        return validationDictionary.REQUIRED;
    }
    if (parseInt(birthDateValue[0], 10) > 31 || parseInt(birthDateValue[0], 10) === 0) {
        return validationDictionary.BIRTH_DATE_EXPIRED;
    }
    if (Number.isNaN(getMonth(new Date(currentDate)))) {
        return validationDictionary.BIRTH_DATE_EXPIRED;
    }
    if (parseInt(birthDateValue[2], 10) === 0) {
        return validationDictionary.BIRTH_DATE_EXPIRED;
    }
    if (parseInt(birthDateValue[2], 10) <= parseInt(currentYear, 10) - 100) {
        return validationDictionary.BIRTH_DATE_EXPIRED;
    }
    if (currentAge < 14 && currentAge > 0) {
        return validationDictionary.PARENTAL_ADVISORY;
    }
    if (currentAge < 0) {
        return validationDictionary.BIRTH_DATE_EXPIRED;
    }
    if (parseInt(birthDateValue[2], 10) >= parseInt(currentYear, 10)) {
        return validationDictionary.BIRTH_DATE_EXPIRED;
    }

    return null;
}

interface Form {
    account?: string;
    birthDate?: string;
    card?: string;
    fullName?: string;
    phone?: string;
    type?: string;
}

type Error = Record<string, string | null>;

function validateForm(form: Form) {
    const error: Error = {
        card: null,
        account: null,
    };

    if (form.type === RegistrationType.Card) {
        error.card = validateCardNumber(form.card);
    } else {
        error.account = validateAccountNumber(form.account);
    }

    if (Object.keys(error).some((field) => error[field])) {
        return error;
    }

    return false;
}

function validateClientRegForm(form: Form) {
    const error: Error = {
        card: null,
        account: null,
    };

    if (form.type === RegistrationType.Card) {
        error.card = validateCardNumber(form.card);
    } else {
        error.account = validateAccountNumber(form.account);
    }

    if (Object.keys(error).some((field) => error[field])) {
        return error;
    }

    return false;
}

function validatePhoneForm(form: Form) {
    const error: Error = {
        phone: null,
    };

    error.phone = validatePhoneNumber(form.phone);
    if (Object.keys(error).some((field) => error[field])) {
        return error;
    }

    return false;
}

function validateFullNameForm(form: Form) {
    const error: Error = {
        fullName: null,
        birthDate: null,
    };

    error.fullName = validateFullName(form.fullName);
    error.birthDate = validateBirthDate(form.birthDate);
    if (Object.keys(error).some((field) => error[field])) {
        return error;
    }

    return false;
}

export const validateRegistration = {
    validateForm,
    validatePhoneForm,
    validateFullNameForm,
    validateClientRegForm,
    validateCardNumber,
    validateAccountNumber,
    validatePhoneNumber,
};
