import React from 'react';

// import loadable from '@loadable/component';
import type { Props } from './card-scanner';
import CardScanner from './card-scanner';
import { ErrorBoundary } from './error-boundary';

// TODO: try to fix lazy-loading - public path does not work right now - PASSPORT-7985
// const LazyScanner = loadable(() => import('./card-scanner') as Promise<any>);

// LazyScanner.preload();

export const Scanner: React.FC<Props> = (props) => (
    <ErrorBoundary>
        <CardScanner {...props} />
    </ErrorBoundary>
);
