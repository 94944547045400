export const FORM_FIELD_SIZE = 'm';
export const FORM_BUTTON_SIZE = 'l';
export const FORM_MOBILE_BUTTON_SIZE = 'm';
export const FORM_BUTTON_SIZE_XL = 'xl';
export const FORM_LABEL_SIZE = 'l';
export const FORM_LABEL_SIZE_M = 'm';
export const FORM_LABEL_SIZE_S = 's';
export const FORM_CHECKBOX_SIZE = 'm';
export const FORM_MOBILE_INPUT_SIZE = 'm';
export const FORM_INPUT_SIZE = 'l';
export const FORM_PHONE_INPUT_SIZE = 'm';
export const FORM_SWITCHER_SIZE = 'm';
export const FORM_LINK_SIZE = 's';
export const FORM_LINK_SIZE_M = 'm';
export const HEADING_SIZE = 'l';

export const CORP_LOGIN_INPUT_MIN_LENGTH = 1;
export const CORP_LOGIN_BUTTON_MIN_LENGTH_DISABLED = 1;
export const CORP_LOGIN_INPUT_MAX_LENGTH = 255;
export const LOGIN_INPUT_MIN_LENGTH = 6;
export const LOGIN_BUTTON_MIN_LENGTH_DISABLED = 6;
export const LOGIN_INPUT_MAX_LENGTH = 64;
export const DIRECT_LOGIN_INPUT_MIN_LENGTH = 1;
export const CORP_PASSWORD_INPUT_MAX_LENGTH = 40;
export const CORP_PASSWORD_INPUT_MIN_LENGTH = 6;
export const CORP_PASSWORD_BUTTON_MIN_LENGTH_DISABLED = 6;
export const PASSWORD_INPUT_MIN_LENGTH = 6;
export const PASSWORD_BUTTON_MIN_LENGTH_DISABLED = 6;
export const PASSWORD_INPUT_MAX_LENGTH = 64;
export const DIRECT_PASSWORD_INPUT_MIN_LENGTH = 1;
export const SMS_INPUT_MAX_LENGTH = 4;
export const NEW_SMS_REQUEST_DELAY = 60;
export const CARD_INPUT_MAX_LENGTH = 19;
export const CARD_EXPIRY_YEAR_MAX_LENGTH = 2;
export const ACCOUNT_INPUT_MAX_LENGTH = 24;
export const PHONE_INPUT_MIN_LENGTH = 7;
export const PHONE_INPUT_MIN_LENGTH_RU = 11;
export const PHONE_INPUT_MAX_LENGTH = 20;
export const FULLNAME_INPUT_MAX_LENGTH = 105;
export const BIRTH_DATE_LENGTH = 10;
export const PINCODE_MIN_LENGTH = 4;
export const PINCODE_MAX_LENGTH = 8;
export const TEMP_PASS_MAX_LENGTH = 6;
export const GRAPHIC_PATTERN_MIN_LENGTH = 5;
export const GRAPHIC_PATTERN_MAX_LENGTH = 9;
export const PASSCODE_ALFANUMERIC_MIN_LENGTH = 6;
export const PASSCODE_TIME_CLEAR = 1400;
