import { getCookie } from '@alfa-bank/newclick-core/utils/get-cookie';

import getPathnameEndpoint from '#/src/lib/get-pathname-endpoint';
import { AuthType, CookiesName } from '#/src/models';
import { QueryRedirectParams } from '#/src/types/interfaces';

const checkPlatform = (isWebview?: boolean, isMobile?: boolean) => {
    const authType = getCookie(CookiesName.authType);

    if (authType === AuthType.Capsule || authType === AuthType.VoskhodApp) {
        return 'voskhod';
    }

    if (isWebview) {
        return 'webview';
    }

    if (isMobile) {
        return 'mobile';
    }

    return 'desktop';
};

export const getMetricsData = (queryRedirectParams: QueryRedirectParams, isMobile?: boolean): Record<string, string | null> => {
    if (typeof window !== 'undefined') {
        const deviceType = navigator.userAgent;
        const path = getPathnameEndpoint(window.location.pathname);
        const clientId = queryRedirectParams?.client_id;
        const browserId = getCookie(CookiesName.browserId);
        const platform = checkPlatform(isMobile);

        return {
            deviceType,
            path,
            clientId,
            browserId,
            platform,
        }
    }

    return {};
}
