/* eslint-disable react/no-danger */
import React, { FC } from 'react';

import { getFHPScript } from '#/src/server/scripts/fhp';
import { ApplicationState } from '#/src/store/types';

export type AppHtmlProps = {
    /** Дочерние элементы */
    children?: React.ReactNode;
    cssFiles?: string[];
    jsFiles?: string[];
    isMobile: boolean;
    useFhp: boolean;
    isCorporateClient: boolean;
    fhpScriptId: string;
    fhpUrl: string;
    fhpCid: string;
    fhpBackUrl: string;
    fhpGafUrl: string;
    /** Имя функции, которая будет запущена после загрузки страницы, должна инициализировать ваше приложение.
     * На вход ей будет передан единственный аргумент, содержащий `appState`.* @default '__main' */
    initAppFunctionName?: string;
    appState: ApplicationState;
    /** Дополнительные параметры необходимые на клиенте */
};

const AppHtml: FC<AppHtmlProps> = (props) => {
    const { children, ...passedProps } = props;
    const {
        cssFiles = [],
        jsFiles = [],
        isMobile,
        isCorporateClient,
        useFhp,
        fhpScriptId,
        fhpUrl,
        fhpCid,
        fhpBackUrl,
        fhpGafUrl,
        appState,
        initAppFunctionName = 'runApp',
    } = passedProps;

    const FHPConfig = {
        fhpScriptId,
        fhpUrl,
        fhpCid,
        fhpBackUrl,
        fhpGafUrl,
    };
    const bodyClassname = isMobile ? 'mobile' : '';
    const FHPScript = getFHPScript(FHPConfig);
    const isWebview =
        appState.App?.queryRedirectParams.isWebview || appState.App?.queryRedirectParams.is_webview;
    const initScript = `
    document.addEventListener(
        'DOMContentLoaded',
        function () {
            window.${initAppFunctionName}(${JSON.stringify(passedProps).replace(/</g, '\\u003c')});
        }
    );
`;

    // Скрипт для правильного отображения window в Webview iOS
    const iOSHeightScript = `

    addEventListener("load", function() {
        document.getElementById("viewport").setAttribute("content", "width=" + window.innerWidth + ", height=" + window.innerHeight + ", initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    })
    `;

    return (
        <html lang='ru'>
            <head>
                <meta charSet='utf-8' />
                <meta
                    id='viewport'
                    name='viewport'
                    content='width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
                />
                <meta httpEquiv='X-UA-Compatible' content='IE=Edge' />
                <meta name='robots' content='noindex' />
                {isCorporateClient ? (
                    <title>Интернет-банк «Альфа-Бизнес Онлайн»</title>
                ) : (
                    <React.Fragment>
                        <title>Мобильный банк Альфа-Онлайн</title>
                        <meta
                            name='description'
                            content='Альфа-Онлайн — это интернет-банк, который работает как на компьютере,
                                      так и в телефоне. В нём можно открывать карты, счета и вклады, совершать платежи
                                      и переводить деньги — быстро, удобно и безопасно.'
                        />
                        <meta
                            name='keywords'
                            content='интернет-банкинг, интернет банк, электронные платежи,
                                      денежные переводы online, денежные переводы через интернет,
                                      денежные переводы круглосуточно, денежные переводы онлайн,
                                      электронные денежные переводы, интернет кошелек, оплата через интернет,
                                      перевести деньги онлайн, перевести деньги online, платежи онлайн,
                                      открыть счет онлайн, открыть вклад онлайн, оформить карту онлайн,
                                      оплатить ЖКХ онлайн, переводы по номеру карты, переводы по номеру телефона,
                                      оплатить штраф онлайн, оплатить налоги онлайн, обмен валюты онлайн,
                                      купить доллары онлайн, купить евро онлайн, купить валюту в интернете,
                                      переводы по реквизитам, взять кредит онлайн, альфа онлайн, альфа-банк, альфа'
                        />
                    </React.Fragment>
                )}
                <link
                    rel='icon'
                    href='data:image/x-icon;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoxRDBCOTc1Mjc1QjMxMUU0Qjk3MDlDQkQyNDczNEM0RSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoxRDBCOTc1Mzc1QjMxMUU0Qjk3MDlDQkQyNDczNEM0RSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjFEMEI5NzUwNzVCMzExRTRCOTcwOUNCRDI0NzM0QzRFIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjFEMEI5NzUxNzVCMzExRTRCOTcwOUNCRDI0NzM0QzRFIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+LWVYkgAAAO1JREFUeNpi/P//PwMlgAWZ89FYHZmrA8SrgRhkQyAQ34RJ8J+FMxmY8BjeDcQaQKwJxPVEuQAJSAOxGxC/gbrAH4h5gfgzukJcLoiByq0D4k1AzAX1BgOxBsRDaVAYbEAylCgvmEH9DXL+ASBmBuIvQOwExJJA/JyQC+KgNMjmP0D8E4i3Qw2KRFfMiJwOgNHIBrVBCIfXzgGxMXI0onvBF6r5OxBfQxLnBGJQIjECYi1kOSYczp8PxCZIWBuIN0LlovAFYg4QXwfipVicPwdq8wx8YUBU+ic2KRMF0A04DE26+PAWYlIi0QAgwAAJpzxcfHyU3gAAAABJRU5ErkJggg=='
                />
                {cssFiles.map((link) => {
                    const src = `${appState.Settings?.contextRoot}${link}`;

                    return <link rel='stylesheet' type='text/css' href={src} key={link} />;
                })}
                <link
                    href='https://alfabank.servicecdn.ru/icons/click/icon_192x192.png'
                    rel='icon'
                />
                <link
                    href='https://alfabank.servicecdn.ru/icons/click/icon_192x192.png'
                    rel='apple-touch-icon'
                    sizes='192x192'
                />
                <meta name='apple-mobile-web-app-title' content='Альфа-Онлайн' />
                {/*
                 * До полной загрузки страницы в Google Chrome происходит срабатывание transition от значений по умолчанию до установленных в стилях.
                 * Например: существует проблема с transition для `color` ссылок и `background-color` в меню.
                 * Чтобы решить данную проблему добавляется пустой `script`, который устраняет данную проблему.
                 * Подробнее на https://stackoverflow.com/questions/14389566/stop-css-transition-from-firing-on-page-load
                 */}
                <script />
            </head>
            <body className={bodyClassname}>
                <div id='react-app'>{children}</div>
                {jsFiles.map((link) => {
                    const src = `${appState.Settings?.contextRoot}${link}`;

                    return <script type='text/javascript' defer={true} src={src} key={link} />;
                })}
                {useFhp && (
                    <script
                        type='text/javascript'
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: FHPScript }}
                    />
                )}
                <script
                    type='text/javascript'
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: initScript }}
                />
                {isWebview && (
                    <script
                        type='text/javascript'
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: iOSHeightScript }}
                    />
                )}
            </body>
        </html>
    );
};

export default AppHtml;
