import { createSelector } from 'reselect';

import hasCloseSms from '#/src/lib/acr-actions-for-sms';
import compareVersions from '#/src/lib/compare-versions';
import {
    AppVersionsNeedUpdate,
    AppVersionsWithBiometryAuth,
    AppVersionsWithNativeCallBank,
    AppVersionsWithNewInvestmentsDesign,
    AppVersionsWithNewMobileDesign,
    AppVersionsWithNewUi,
    AppVersionsWithScanner,
    AppVersionsWithSupportInfoScreen,
    ClientIds,
    DeviceOSNames,
    DeviceOSVersionWithNewUi,
} from '#/src/models';
import { ApplicationState } from '#/src/store/types';
import { hasOwnProperty, isString } from '#/src/types/base-guards';

/** Функция получения общего состояния приложения. */
export const getAppState = (state: ApplicationState) => state?.App;

export const getClientId = createSelector([getAppState], (App) => App.clientId);

export const getQueryRedirectParams = createSelector(
    [getAppState],
    (App) => App?.queryRedirectParams,
);

export const selectMultiFactorResponseParams = createSelector(
    [getAppState],
    (App) => App?.multiFactorResponseParams,
);

export const selectIsWebAuthnEnabled = createSelector(
    [getAppState],
    (App) => App?.isWebAuthnEnabled,
);

export const selectIsWebAuthnRegEnabled = createSelector(
    [getAppState],
    (App) => App?.webAuthnRegEnabled,
);

export const selectIsBrowserSecretEnabled = createSelector(
    [getAppState],
    (App) => App?.isBrowserSecretEnabled,
);

export const selectNotificationsData = createSelector(
    [getAppState],
    (App) => App?.notificationsData,
);

export const selectOldBrowser = createSelector([getAppState], (App) => App?.oldBrowser);

export const selectIsCardScannerEnabled = createSelector(
    [getAppState],
    (App) => App?.isCardScannerEnabled,
);

export const selectBecomeClientUrl = createSelector([getAppState], (App) => App.become_client_url);

export const selectIsWebView = createSelector(
    [getAppState],
    (App) => App?.queryRedirectParams.isWebview || App?.queryRedirectParams?.is_webview,
);

export const selectBrowserSecretPublicKey = createSelector(
    [getAppState],
    (App) => App.browserSecretPublicKey,
);

export const getMaskedPhoneNumber = createSelector(
    [selectMultiFactorResponseParams],
    (multiFactorParams) => multiFactorParams.masked_phone,
);

export const getDeviceAppVersion = createSelector(
    [getQueryRedirectParams],
    (params) => params?.device_app_version,
);

export const getDeviceOS = createSelector(
    [getQueryRedirectParams],
    (redirectParams) => redirectParams?.device_os_version,
);

export const selectGetDeviceOSName = createSelector(getDeviceOS, (deviceOSVersion) => {
    for (let i = 0; i < DeviceOSNames.length; i++) {
        if (isString(deviceOSVersion)) {
            if (new RegExp(`^${DeviceOSNames[i]}`, 'i').test(deviceOSVersion))
                return DeviceOSNames[i];
        }
    }

    return '';
});

export const getDeviceOSVersion = createSelector(getDeviceOS, (deviceOS) => {
    let version = ['0'];

    if (isString(deviceOS)) {
        version = deviceOS.match(/\d+/) || ['0'];
    }

    return version[0];
});

export const getDeviceOSFullVersion = createSelector(getDeviceOS, (deviceOS) => {
    let version = ['0.0.0'];

    if (isString(deviceOS)) {
        version = deviceOS.match(/\d+.\d+.\d+/) || ['0.0.0'];
    }

    return version[0];
});

export const parsedDeviceAppVersion = createSelector([getDeviceAppVersion], (deviceAppVersion) => {
    if (typeof deviceAppVersion === 'string' && deviceAppVersion.includes(' ')) {
        return deviceAppVersion.split(' ')[0];
    }

    return deviceAppVersion;
});

export const isAppVersionHasNewUi = createSelector(
    [selectGetDeviceOSName, parsedDeviceAppVersion],
    (deviceOS, deviceAppVersion) => {
        if (hasOwnProperty(AppVersionsWithNewUi, deviceOS)) {
            return compareVersions(deviceAppVersion, AppVersionsWithNewUi[deviceOS]) >= 0;
        }

        return false;
    },
);

export const isDeviceVersionHasNewUi = createSelector(
    [selectGetDeviceOSName, getDeviceOSVersion],
    (deviceOS, deviceOSVersion) => {
        if (hasOwnProperty(DeviceOSVersionWithNewUi, deviceOS)) {
            return compareVersions(deviceOSVersion, DeviceOSVersionWithNewUi[deviceOS]) >= 0;
        }

        return false;
    },
);

export const getMobileOnboardingDesignOsVersions = createSelector(
    [getAppState],
    (App) => App.mobileOnboardingDesignOsVersions,
);

export const selectIsMobileOnboardingDesignVariant = createSelector(
    [
        getQueryRedirectParams,
        getMobileOnboardingDesignOsVersions,
        selectGetDeviceOSName,
        getDeviceOSVersion,
    ],
    (queryRedirectParams, mobileOnboardingDesignOsVersions, deviceOS, deviceOSVersion) =>
        hasOwnProperty(mobileOnboardingDesignOsVersions, deviceOS) &&
        compareVersions(deviceOSVersion, mobileOnboardingDesignOsVersions[deviceOS]) >= 0 &&
        queryRedirectParams.design_variant === 'mobile_onboarding',
);

export const selectIsMobileNewVersion = createSelector(
    [selectGetDeviceOSName, parsedDeviceAppVersion],
    (deviceOS, deviceAppVersion) => {
        if (hasOwnProperty(AppVersionsWithNewMobileDesign, deviceOS)) {
            return compareVersions(deviceAppVersion, AppVersionsWithNewMobileDesign[deviceOS]) >= 0;
        }

        return false;
    },
);

export const isInvestmentsNewDesign = createSelector(
    [getClientId],
    (clientId) => clientId === ClientIds.investmentsMobile || clientId === ClientIds.adirectWebpro,
);

export const isInvestmentsNewVersion = createSelector(
    [selectGetDeviceOSName, parsedDeviceAppVersion],
    (deviceOS, deviceAppVersion) => {
        if (hasOwnProperty(AppVersionsWithNewInvestmentsDesign, deviceOS)) {
            return (
                compareVersions(deviceAppVersion, AppVersionsWithNewInvestmentsDesign[deviceOS]) >=
                0
            );
        }

        return false;
    },
);

export const hasSmsModalCloser = createSelector(
    [getQueryRedirectParams, selectIsMobileOnboardingDesignVariant, isInvestmentsNewDesign],
    (queryRedirectParams, mobileOnboardingDesignVariant, investmentsNewDesign) => {
        if (
            mobileOnboardingDesignVariant ||
            queryRedirectParams.client_id === ClientIds.X5 ||
            queryRedirectParams.client_id === ClientIds.lkx5Web ||
            investmentsNewDesign
        )
            return false;

        return hasCloseSms(queryRedirectParams.acr_values);
    },
);

export const isNewUi = createSelector(
    [isAppVersionHasNewUi, isDeviceVersionHasNewUi],
    (AppVersionHasNewUi, deviceVersionHasNewUi) => AppVersionHasNewUi && deviceVersionHasNewUi,
);

export const selectIsAppVersionHasScanner = createSelector(
    [selectGetDeviceOSName, getDeviceOSFullVersion],
    (deviceOS, deviceAppVersion) => {
        if (hasOwnProperty(AppVersionsWithScanner, deviceOS)) {
            return compareVersions(deviceAppVersion, AppVersionsWithScanner[deviceOS]) >= 0;
        }

        return false;
    },
);

export const selectIsAlfaDirectClientId = createSelector(getClientId, (clientId) =>
    new RegExp(/^adirect-/i).test(clientId),
);

export const formatMaskedPhoneNumber = createSelector(getMaskedPhoneNumber, (phoneNumber) => {
    let match;

    if (isString(phoneNumber)) {
        match = phoneNumber.match(/^(\d{1})(\*{3})(\*{1,11})(.{2})(.{2})$/);
    }

    // звездочки меняю на 000 тк в компоненте @alfalab/core-components/confirmation,
    // если передавать не числа, ломается маскирование
    return match ? `+${match[1]} 000 000 ${match[4]} ${match[5]}` : '';
});

export const isClickDesign = createSelector(
    getClientId,
    (clientId) =>
        clientId === ClientIds.adfClickWeb ||
        clientId === ClientIds.clickWeb ||
        clientId === ClientIds.clickWebV2 ||
        clientId === ClientIds.clickUpsaleCardsWeb ||
        clientId === ClientIds.newClickWeb ||
        clientId === ClientIds.newclickUzesiaWeb ||
        clientId === ClientIds.passportWebDemoWeb ||
        clientId === ClientIds.ecoClickWeb ||
        clientId === ClientIds.upsaleVerificationClickWeb ||
        clientId === ClientIds.relendClickWeb ||
        clientId === ClientIds.retailUpsaleCardsAnketsWeb ||
        clientId === ClientIds.retailUpsaleCardsWeb ||
        clientId === ClientIds.alfaIdAuthorize ||
        clientId === ClientIds.alfaformDcDt2ao,
);

export const selectIsCorpDesign = createSelector(getClientId, (clientId) =>
    new RegExp(/^corp-|^albo/i).test(clientId),
);

export const selectIsBankMessenger = createSelector(
    getClientId,
    (clientId) =>
        clientId === ClientIds.messengerVkBank || clientId === ClientIds.messengerTelegramBank,
);

export const selectIsVkMessenger = createSelector(
    getClientId,
    (clientId) => clientId === ClientIds.messengerVkBank,
);

export const selectIsCorporateClientId = createSelector(getClientId, (clientId) =>
    new RegExp(/^corp-|^albo/i).test(clientId),
);

export const selectIsCorpAlboClientId = createSelector(
    getClientId,
    (clientId) => clientId === ClientIds.corpAlbo,
);

export const selectIsWhiteListAdminClientId = createSelector(
    getClientId,
    (clientId) => clientId === 'corp-white-list-admin',
);

export const selectIsAlfaId = createSelector(
    getClientId,
    (clientId) => clientId === ClientIds.alfaIdAuthorize,
);

export const selectIsMobile = createSelector([getAppState], (App) => App?.isMobile);
export const selectClientId = createSelector(
    [getQueryRedirectParams],
    (redirectParams) => redirectParams?.client_id,
);
export const selectScope = createSelector(
    [getQueryRedirectParams],
    (redirectParams) => redirectParams?.scope,
);
export const selectRedirectURI = createSelector(
    [getQueryRedirectParams],
    (redirectParams) => redirectParams?.redirect_uri,
);
export const selectState = createSelector(
    [getQueryRedirectParams],
    (redirectParams) => redirectParams?.state,
);
export const selectNonce = createSelector(
    [getQueryRedirectParams],
    (redirectParams) => redirectParams?.nonce,
);
export const selectIsAKeyEnabled = createSelector([getAppState], (App) => App.isAKeyEnabled);
export const selectIsAKeyVisited = createSelector([getAppState], (App) => App.isAKeyVisited);
export const selectIosAppVersionRedirect = createSelector(
    [getAppState],
    (App) => App.iosAppVersionRedirect,
);
export const selectIosAppIdRedirect = createSelector([getAppState], (App) => App.iosAppIdRedirect);
export const selectMobileAppDownloadLink = createSelector(
    [getAppState],
    (App) => App.mobileAppDownloadLink,
);
export const selectMultiFactorResponseRedirectUrl = createSelector(
    [getAppState],
    (App) => App.multiFactorResponseRedirectUrl,
);
export const selectInvestmentsUrl = createSelector([getClientId, getAppState], (clientId, App) => {
    if (clientId === ClientIds.adirectWebpro) {
        return App.investments_web_url;
    }

    return App.investments_url;
});

export const selectPassword = createSelector([getAppState], (App) => App.password);
export const selectIsGameEnabled = createSelector([getAppState], (App) => App.isGameEnabled);
export const selectGameUrl = createSelector([getAppState], (App) => App.gameUrl);
export const selectFirstVisitedMetrics = createSelector(
    [getAppState],
    (App) => App.firstVisitedMetrics,
);
export const selectIsFingerPrintEnabled = createSelector(
    [getAppState],
    (App) => App?.isFingerPrintEnabled,
);
export const selectFirstVisitedLogin = createSelector(
    [selectFirstVisitedMetrics],
    (firstVisitedMetrics) => firstVisitedMetrics.login,
);
export const selectFirstVisitedPassword = createSelector(
    [selectFirstVisitedMetrics],
    (firstVisitedMetrics) => firstVisitedMetrics.password,
);
export const selectMfaToken = createSelector(
    [selectMultiFactorResponseParams],
    (params) => params?.mfa_token,
);
export const selectIsX5Web = createSelector(
    getClientId,
    (clientId) => clientId === ClientIds.lkx5Web,
);
export const selectIsMobileApp = createSelector(
    getClientId,
    (clientId) =>
        clientId === ClientIds.mobileApp ||
        clientId === ClientIds.messengerVkBank ||
        clientId === ClientIds.messengerTelegramBank ||
        clientId === ClientIds.X5 ||
        clientId === ClientIds.lkx5Web,
);

export const isAppVersionHasSupportInfoScreen = createSelector(
    [parsedDeviceAppVersion, selectGetDeviceOSName],
    (deviceAppVersion, deviceOS) => {
        if (hasOwnProperty(AppVersionsWithSupportInfoScreen, deviceOS)) {
            return (
                compareVersions(deviceAppVersion, AppVersionsWithSupportInfoScreen[deviceOS]) >= 0
            );
        }

        return false;
    },
);

export const isAppVersionHasNativeCallBank = createSelector(
    [parsedDeviceAppVersion, selectGetDeviceOSName],
    (deviceAppVersion, deviceOS) => {
        if (hasOwnProperty(AppVersionsWithNativeCallBank, deviceOS)) {
            return compareVersions(deviceAppVersion, AppVersionsWithNativeCallBank[deviceOS]) >= 0;
        }

        return false;
    },
);

export const isAppVersionNeedUpdate = createSelector(
    [parsedDeviceAppVersion, selectGetDeviceOSName],
    (deviceAppVersion, deviceOS) => {
        if (hasOwnProperty(AppVersionsNeedUpdate, deviceOS)) {
            return compareVersions(deviceAppVersion, AppVersionsNeedUpdate[deviceOS]) <= 0;
        }

        return false;
    },
);

export const selectIsPincodeEnabled = createSelector([getAppState], (App) => App?.isPincodeEnabled);
export const isGlobalPreloaderVisible = createSelector([getAppState], (App) => App?.preloaderShown);
export const selectIsAccountAuthEnabled = createSelector(
    [getAppState],
    (App) => App?.isAccountAuthEnabled,
);

export const selectChangeFlow = createSelector([getAppState], (App) => App.changedFlow);

export const notificationsData = createSelector([getAppState], (App) => App.notificationsData);

export const selectGosUslugiAuthorizationUrl = createSelector(
    [getAppState],
    (App) => App.gos_uslugi_authorization_url,
);
export const selectPasswordRecoverLink = createSelector(
    [getAppState],
    (App) => App.passwordRecoverLink,
);
export const selectLoginRecoverLink = createSelector([getAppState], (App) => App.loginRecoverLink);

export const selectMetricsConfig = createSelector([getAppState], (App) => ({
    alfaMetricsUrl: App?.metricsUrl,
    endpointUrl: App?.metricsEndpointUrl,
    appId: App?.metricsAppId,
}));
export const selectMetricsDebugEnabled = createSelector([getAppState], (App) => App?.metricsDebugEnabled);
export const selectAKeyTimeout = createSelector([getAppState], (App) => App?.AKeyTimeout);
export const selectAKeyRequestCount = createSelector([getAppState], (App) => App?.AKeyRequestCount);
export const selectIsUpdateBannerEnabled = createSelector(
    [getAppState],
    (App) => App?.isUpdateBannerEnabled,
);
export const isAppVersionHasBiometryAuth = createSelector(
    [parsedDeviceAppVersion, selectGetDeviceOSName],
    (deviceAppVersion, deviceOS) => {
        if (hasOwnProperty(AppVersionsWithBiometryAuth, deviceOS)) {
            return compareVersions(deviceAppVersion, AppVersionsWithBiometryAuth[deviceOS]) >= 0;
        }

        return false;
    },
);

export const selectIsAlfaIdBannerEnabled = createSelector(
    [getAppState],
    (App) => App?.isAlfaIdBannerEnabled,
);
