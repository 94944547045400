import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';

import { AlfaIdBannerContent } from '../alfaid-banner-content';

import './alfaid-banner-desktop.css';

const cn = createCn('alfaid-banner-desktop');

export const AlfaIdBannerDesktop: FC = () => (
    <div className={cn()}>
        <AlfaIdBannerContent />
    </div>
);
