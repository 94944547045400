import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';

import { AlfaIdBannerContent } from '../alfaid-banner-content';

import './alfaid-banner-mobile.css';

const cn = createCn('alfaid-banner-mobile');

export const AlfaIdBannerMobile: FC = () => (
    <div className={cn()}>
        <AlfaIdBannerContent isMobile={true} />
    </div>
);
