import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { InternationalPhoneInput } from '@alfalab/core-components/international-phone-input';
import Form from 'arui-feather/form';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { PHONE_INPUT_MIN_LENGTH, PHONE_INPUT_MIN_LENGTH_RU } from '#/src/lib/form-controls-const';
import { formatPhoneNumber } from '#/src/lib/formatters';
import { enterDemoOnIOS } from '#/src/lib/passport-mobile-bridge';
import { phoneNumberValidate } from '#/src/lib/validators';
import { ButtonNames, DEMO_PHONE_NUMBER, FormStatus, RegistrationType, Routes } from '#/src/models';
import { useRequestPhoneRegistrationMutation } from '#/src/store/api/registration-api';
import { getQueryRedirectParams, selectIsMobile } from '#/src/store/redux/app/selectors';
import {
    selectCustomDesignBackUrl,
    selectIsAlfaIdDesign,
} from '#/src/store/redux/custom-design/selectors';
import {
    getRegistrationFormStatus,
    getRegistrationPhone,
    phoneAuthErrorMessage,
} from '#/src/store/redux/registration/selectors';
import {
    phoneRegistrationSubmit,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationTypeUpdated,
} from '#/src/store/redux/registration/slice';

import QRLogin from '../../qr-login';
import BackButton from '../../ui/back-button';
import { GreetingTitle } from '../../ui/greeting-title';
import Help from '../../ui/help';

import './phone-auth.css';

const cn = createCn('phone-auth-browser');

const AUTO_REDIRECT_FLAG = 'phone_auth_auto_redirect';

const PhoneAuthBrowser: FC = () => {
    const [firstKeyPush, setFirstKeyPush] = useState(false);

    const dispatch = useAppDispatch();
    const [requestPhoneRegistration] = useRequestPhoneRegistrationMutation();
    const location = useLocation();
    const navigate = useNavigate();

    const phone = useAppSelector(getRegistrationPhone);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const errorMessage = useAppSelector(phoneAuthErrorMessage);
    const isMobile = useAppSelector(selectIsMobile);
    const customDesignBackUrl = useAppSelector(selectCustomDesignBackUrl);
    const isAlfaId = useAppSelector(selectIsAlfaIdDesign);

    useEffect(() => {
        // для корректной работы кнопки назад на странице card_account
        const alreadyRedirected = sessionStorage.getItem(AUTO_REDIRECT_FLAG);

        if (alreadyRedirected) return;

        const isPhoneValid = phoneNumberValidate(queryRedirectParams.phone, { withLength: true });

        dispatch(registrationTypeUpdated(RegistrationType.Phone));
        if (isPhoneValid) {
            dispatch(
                registrationFormUpdated({
                    phone: formatPhoneNumber(queryRedirectParams.phone),
                }),
            );
            sessionStorage.setItem(AUTO_REDIRECT_FLAG, 'true');
            requestPhoneRegistration();
            trackUserEvent(
                'Auth Page',
                'Impression',
                'Viewing Page',
                queryRedirectParams.client_id,
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    }, [formStatus, requestPhoneRegistration]);

    useEffect(() => {
        if (errorMessage) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Phone Send',
                queryRedirectParams.client_id,
                `Error: ${errorMessage}`,
            );
        }
    }, [errorMessage, queryRedirectParams.client_id]);

    const handleSubmit = (event?: React.FormEvent<any>) => {
        event?.preventDefault();
        trackUserEvent(
            'Auth Page',
            'Click',
            'Phone Send',
            queryRedirectParams.client_id,
            'Sending',
        );

        if (phone === DEMO_PHONE_NUMBER) {
            enterDemoOnIOS();
        } else {
            dispatch(
                phoneRegistrationSubmit({
                    type: RegistrationType.Phone,
                    phone,
                }),
            );
        }
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    };

    const handlePhoneChange = (phoneValue: string) => {
        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Phone Number',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }

        dispatch(registrationFormUpdated({ phone: phoneValue }));

        if (errorMessage) {
            dispatch(registrationServerErrorsCleared());
        }
    };

    const isSubmitButtonDisabled = () => {
        if (phone) {
            const phoneNumber = phone.replace(/\D/g, '');
            const PHONE_INPUT_MIN_LENGTH_RU_CHECKED = /^7/.test(phoneNumber)
                ? PHONE_INPUT_MIN_LENGTH_RU
                : PHONE_INPUT_MIN_LENGTH;

            return phoneNumber.length < PHONE_INPUT_MIN_LENGTH_RU_CHECKED || !!errorMessage;
        }

        return true;
    };

    const asSecondaryPage = location.state?.asSecondaryPage;

    return (
        <Form className={cn()} onSubmit={handleSubmit}>
            {!isMobile && customDesignBackUrl && (
                <div className={cn('back-button')}>
                    <BackButton backUrl={customDesignBackUrl} />
                </div>
            )}
            {!asSecondaryPage ? <GreetingTitle /> : null}
            <div className={cn('input')}>
                <InternationalPhoneInput
                    dataTestId='phoneInput'
                    block={true}
                    clear={true}
                    size={isMobile ? 64 : 72}
                    clearableCountryCode='preserve'
                    autoFocus={true}
                    value={phone}
                    error={errorMessage}
                    colors='default'
                    label='Номер телефона'
                    onChange={handlePhoneChange}
                />
            </div>

            <Button
                block={true}
                type='submit'
                view='primary'
                disabled={isSubmitButtonDisabled()}
                loading={formStatus === FormStatus.SubmitProcess}
                className={cn('submit-button')}
            >
                {ButtonNames.forward}
            </Button>

            <Button
                dataTestId='login-auth-button'
                onClick={() =>
                    navigate(
                        { pathname: Routes.LOGIN, search: location.search },
                        { state: { asSecondaryPage } },
                    )
                }
                block={true}
                view='text'
                className={cn('login-button')}
            >
                Войти по логину и паролю
            </Button>

            {!isMobile && !isAlfaId && <QRLogin className={cn('qr')} />}

            {!isAlfaId ? <Help className={cn('help')} /> : null}
        </Form>
    );
};

export default PhoneAuthBrowser;
