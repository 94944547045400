import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { NBSP } from 'arui-private/lib/formatters';

import './alfaid-banner-content.css';

const RESTORE_DATA_LINK = 'https://link.alfabank.ru/dashboard';
const RESTORE_LOGIN_LINK =
    'https://alfabank.ru/help/t/corp/alfa-business-start/96-vkhod-v-internet-bank/vkhod-v-internet-bank/ne-pomnyu-login-dlya-vkhoda-v-internet-bank-chto-delat/';
const RESTORE_PASSWORD_LINK =
    'https://alfabank.ru/help/t/corp/alfa-business-start/96-vkhod-v-internet-bank/vkhod-v-internet-bank/ne-pomnyu-parol-dlya-vkhoda-v-internet-bank-chto-delat/';

const cn = createCn('alfaid-banner-content');

export type AlfaIdBannerContentProps = {
    isMobile?: boolean;
};

export const AlfaIdBannerContent: FC<AlfaIdBannerContentProps> = ({ isMobile }) => (
    <div>
        <div className={cn('loudspeaker', { mobile: Boolean(isMobile) })} />
        <Gap size={isMobile ? 'l' : '3xl'} />
        <Typography.Title tag='h3' view={isMobile ? 'medium' : 'large'} font='system'>
            Новый вход в банк
        </Typography.Title>
        <Gap size={isMobile ? 'm' : 'xl'} />
        <Typography.Text tag='div' view={isMobile ? 'primary-medium' : 'primary-large'}>
            26{NBSP}ноября понадобится вручную ввести логин и{NBSP}пароль.
        </Typography.Text>
        <Gap size={isMobile ? 'xs' : 'm'} />
        <Typography.Text tag='div' view={isMobile ? 'primary-medium' : 'primary-large'}>
            Вспомните их{NBSP}прямо сейчас, чтобы{NBSP}не{NBSP}потерять доступ в{NBSP}интернет-банк.
        </Typography.Text>
        <Gap size={isMobile ? 'm' : '2xl'} />
        <Button view='accent' size={isMobile ? 'xs' : 's'} href={RESTORE_DATA_LINK} target='_blank'>
            Вспомнить
        </Button>
        <Gap size={isMobile ? 'm' : '2xl'} />
        <Typography.Text tag='p' view='primary-small' color='secondary'>
            Инструкции, как узнать{' '}
            <Link view='secondary' href={RESTORE_LOGIN_LINK} target='_blank'>
                логин
            </Link>{' '}
            и{NBSP}
            <Link view='secondary' href={RESTORE_PASSWORD_LINK} target='_blank'>
                пароль
            </Link>
        </Typography.Text>
    </div>
);
