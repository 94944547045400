export type FHPConfig = {
    fhpScriptId: string;
    fhpUrl: string;
    fhpCid: string;
    fhpBackUrl: string;
    fhpGafUrl: string;
};

export function getFHPScript(config: FHPConfig) {
    const {
        fhpScriptId,
        fhpUrl,
        fhpCid,
        fhpBackUrl,
        fhpGafUrl,
    } = config;

    return `
    const fhpScript = document.createElement('script');
    fhpScript.id = \`${fhpScriptId}\`;
    fhpScript.src = \`${fhpUrl}\`;
    document.head.append(fhpScript);
    fhpScript.onload = function() {
        window.gib?.init( {
            cid: "${fhpCid}", // Строковый идентификатор клиента.
            backUrl: "${fhpBackUrl}", // URL для отправки данных из Web Snippet.
            gafUrl: "${fhpGafUrl}", // Задает URL загрузки невидимого IFRAME-элемента для выставления Global ID
            silentAlive: false, // Задает условия для генерации сессионного токена, который хранится в файлах cookie и передается с каждым пакетом данных
        } );
        window.gib.setAuthStatus(window.gib.IS_GUEST); // пользователь не авторизован
        setTimeout(() => {
            window.gib.setAttribute('event_type', 'sso');
        }, 500)
    }`;
}
