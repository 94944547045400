import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import IconSubmit from 'arui-feather/icon/ui/submit';
import IconButton from 'arui-feather/icon-button';
import Link from 'arui-feather/link';
import Spin from 'arui-feather/spin';

import BusinessLinks from '#/src/components/ui/business-links';
import InputPassword from '#/src/components/ui/input-password/input-password';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { validatePassword } from '#/src/lib/client-validation/authorization';
import {
    CORP_PASSWORD_BUTTON_MIN_LENGTH_DISABLED,
    CORP_PASSWORD_INPUT_MAX_LENGTH,
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_INPUT_SIZE,
    PASSWORD_BUTTON_MIN_LENGTH_DISABLED,
    PASSWORD_INPUT_MAX_LENGTH,
} from '#/src/lib/form-controls-const';
import getPathnameEndpoint from '#/src/lib/get-pathname-endpoint';
import { FormStatus, Routes, ValidationType } from '#/src/models';
import { useRequestOIDAuthByLoginMutation } from '#/src/store/api/authorization-api';
import {
    getQueryRedirectParams,
    selectFirstVisitedPassword,
    selectIsAlfaIdBannerEnabled,
    selectIsCorpAlboClientId,
    selectIsCorporateClientId,
    selectIsMobile,
    selectMobileAppDownloadLink,
} from '#/src/store/redux/app/selectors';
import { keyboardLayoutChecked, pageFirstVisited, passwordSet } from '#/src/store/redux/app/slice';
import {
    getAuthorizationFormError,
    getAuthorizationFormStatus,
    getAuthorizationLogin,
    getAuthorizationMaskedLogin,
    getAuthorizationPassword,
    getAuthorizationServerErrors,
} from '#/src/store/redux/authorization/selectors';
import {
    authorizationErrorUpdated,
    authorizationFormUpdated,
    authorizationPasswordReset,
    authorizationValidated,
    authServerErrorNotificationClosed,
} from '#/src/store/redux/authorization/slice';
import { selectRegistrationErrorUpdate } from '#/src/store/redux/registration/selectors';
import { errorUpdateMessageDeleted } from '#/src/store/redux/registration/slice';

import addAttributes from '../../../utils/decorators/add-attributes';
import InfoLinks from '../../ui/info-links';
import InputCase from '../../ui/input-case';
import Keyboard from '../../ui/keyboard';
import ServerErrorNotifications from '../../ui/server-errors-notification/server-errors-notification';

import './password.css';

const AttributedIconButton = addAttributes(IconButton, [{ name: 'aria-label', value: 'Войти' }]);

const cn = createCn('form-basic');

const Password: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [requestOIDAuthByLogin] = useRequestOIDAuthByLoginMutation();

    const errorUpdate = useAppSelector(selectRegistrationErrorUpdate);
    const login = useAppSelector(getAuthorizationLogin);
    const maskedLogin = useAppSelector(getAuthorizationMaskedLogin);
    const password = useAppSelector(getAuthorizationPassword);
    const formStatus = useAppSelector(getAuthorizationFormStatus);
    const error = useAppSelector(getAuthorizationFormError);
    const serverErrors = useAppSelector(getAuthorizationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const isCorporateClientId = useAppSelector(selectIsCorporateClientId);
    const firstVisitedStatus = useAppSelector(selectFirstVisitedPassword);
    const mobileAppDownloadLink = useAppSelector(selectMobileAppDownloadLink);
    const isMobile = useAppSelector(selectIsMobile);
    const isAlfaIdBannerEnabled = useAppSelector(selectIsAlfaIdBannerEnabled);
    const isCorpAlboClientId = useAppSelector(selectIsCorpAlboClientId);

    const currentRoute = getPathnameEndpoint(pathname);
    const minLengthButtonDisabled = isCorporateClientId
        ? CORP_PASSWORD_BUTTON_MIN_LENGTH_DISABLED
        : PASSWORD_BUTTON_MIN_LENGTH_DISABLED;
    const maxPasswordLength = isCorporateClientId
        ? CORP_PASSWORD_INPUT_MAX_LENGTH
        : PASSWORD_INPUT_MAX_LENGTH;
    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [capsLockPushed, setCapsLockPushed] = useState(0);
    const [layoutName, setLayoutName] = useState('default');
    const inputRef: React.RefObject<HTMLInputElement> = useRef(null);

    const handleValidatePassword = (password: string) => {
        const validationStatus = validatePassword(password, isCorporateClientId);

        if (validationStatus !== null) {
            dispatch(
                authorizationValidated({
                    validationStatus,
                    validationType: ValidationType.Password,
                }),
            );
        }
    };

    const isButtonDisabled = () =>
        password.length < minLengthButtonDisabled ||
        formStatus === FormStatus.SubmitProcess ||
        !!error.password;

    const authorizationByLogin = () => {
        requestOIDAuthByLogin({
            username: login,
            password,
            queryRedirectParams,
            currentRoute,
        });
    };

    useEffect(() => {
        if (login) {
            inputRef.current?.focus();

            if (password) {
                handleValidatePassword(password);
            }
        } else {
            navigate({
                pathname: Routes.LOGIN,
                search,
            });
        }
        if (!firstVisitedStatus) {
            dispatch(pageFirstVisited('password'));
            trackUserEvent(
                'Auth Page',
                'Impression',
                'Viewing Page',
                queryRedirectParams.client_id,
            );
        }

        return () => {
            dispatch(authorizationPasswordReset());
            if (errorUpdate) dispatch(errorUpdateMessageDeleted());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLinkInLabelClick = () => {
        navigate({
            pathname: Routes.LOGIN,
            search,
        });
    };

    const handlenotificationClosed = () => {
        dispatch(authServerErrorNotificationClosed());
    };

    const handleChangePassword = (password: string) => {
        if (error.password) {
            dispatch(
                authorizationErrorUpdated({
                    password: '',
                }),
            );
        }

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Fill Password',
                queryRedirectParams.client_id,
            );

            setFirstKeyPush(true);
        }

        handleValidatePassword(password);

        dispatch(
            authorizationFormUpdated({
                password,
            }),
        );
        dispatch(passwordSet(password));
        dispatch(keyboardLayoutChecked({ character: password.slice(-1), input: 'password' }));
    };

    const handleSubmit = (event?: FormEvent) => {
        event?.preventDefault();
        if (!isButtonDisabled()) {
            const validationStatus = validatePassword(password, isCorporateClientId);

            if (validationStatus !== null) {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Password Send',
                    queryRedirectParams.client_id,
                    'Error Password Validation',
                );
            }

            if (validationStatus === null) {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Password Send',
                    queryRedirectParams.client_id,
                    'Send',
                );
                authorizationByLogin();
            }
        }
    };

    const handleOnKeyPress = (button: string) => {
        if (button === 'caps') {
            setCapsLockPushed((oldValue) => oldValue + 1);
        }

        if (button === 'shift' || button === 'caps') {
            const layout = layoutName === 'default' ? 'shift' : 'default';

            setLayoutName(layout);

            return;
        }

        if (error.password) {
            dispatch(
                authorizationErrorUpdated({
                    password: '',
                }),
            );
        }

        if (button === 'backspace') {
            dispatch(
                authorizationFormUpdated({
                    password: password.slice(0, -1),
                }),
            );
            handleValidatePassword(password.slice(0, -1));
        } else if (button.length === 1 || button === '.com') {
            let newPassword = '';

            newPassword = password + button;
            dispatch(
                authorizationFormUpdated({
                    password: newPassword,
                }),
            );
            handleValidatePassword(newPassword);
        }

        if (capsLockPushed % 2 === 0) {
            setLayoutName('default');
        }
    };

    const handleShowKeyboard = () => {
        trackUserEvent('Auth Page', 'Click', 'Show Keyboard', queryRedirectParams.client_id);
        setShowKeyboard((prevState) => !prevState);
    };

    return (
        <Form className={cn()} noValidate={true} onSubmit={handleSubmit}>
            <ServerErrorNotifications
                serverErrors={serverErrors}
                onnotificationClosed={handlenotificationClosed}
            />
            <FormField size={FORM_FIELD_SIZE}>
                <InputCase>
                    <InputPassword
                        inputRef={inputRef}
                        id='password-input'
                        password={password}
                        width='available'
                        error={error.password}
                        resetError={false}
                        name='password'
                        maxLength={maxPasswordLength}
                        size={FORM_INPUT_SIZE}
                        label={
                            <span>
                                Пароль к{' '}
                                <Link
                                    pseudo={true}
                                    text={maskedLogin || login}
                                    onClick={handleLinkInLabelClick}
                                />
                            </span>
                        }
                        visibleIcon={false}
                        showEye={isCorporateClientId}
                        visibleKeyboard={handleShowKeyboard}
                        rightAddons={
                            <AttributedIconButton
                                id='password-submit'
                                disabled={isButtonDisabled()}
                                icon={
                                    formStatus === FormStatus.SubmitProcess ? (
                                        <Spin size={FORM_BUTTON_SIZE} visible={true} />
                                    ) : (
                                        <IconSubmit size={FORM_BUTTON_SIZE} />
                                    )
                                }
                                onClick={handleSubmit}
                            />
                        }
                        onInputChange={handleChangePassword}
                    />
                </InputCase>
            </FormField>
            <div>
                <InfoLinks form='password' errorUpdate={!!errorUpdate} />
                {isCorporateClientId && (
                    <BusinessLinks
                        mobileAppDownloadLink={mobileAppDownloadLink}
                        isShowAlfaIdBanner={isAlfaIdBannerEnabled && isCorpAlboClientId && isMobile}
                    />
                )}
            </div>
            {showKeyboard ? <Keyboard layoutName={layoutName} onClick={handleOnKeyPress} /> : null}
        </Form>
    );
};

export default Password;
