import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import errorDictionary from '#/src/error-dictionary';
import { checkPincodeParams } from '#/src/lib/check-pincode-pararms';
import { clientInfoLog } from '#/src/lib/client-logger';
import getCookie from '#/src/lib/get-cookie';
import { setBrowserSecret } from '#/src/lib/set-browser-secret';
import { initFP } from '#/src/lib/sinc-fingerprint';
import { webauthnAuthorization } from '#/src/lib/webauthn-authorization';
import {
    webAuthnParseAuth,
    webauthnParseChallenge,
    webAuthnParseReg,
} from '#/src/lib/webauthn-parse';
import { CookiesName, LocalStorageName } from '#/src/models';
import { customFetchBaseQuery } from '#/src/store/base-query';
import { selectClientId } from '#/src/store/redux/app/selectors';
import { webAuthnStatusSet } from '#/src/store/redux/app/slice';
import { selectFingerPrintCredentials } from '#/src/store/redux/fingerprint/selectors';
import { webAuthnErrorSet } from '#/src/store/redux/webauthn/slice';
import { ApplicationState } from '#/src/store/types';
import {
    WebAuthnAuthorizationCredentials,
    WebAuthnRegChallengeCredentials,
    WebAuthnRegistrationCredentials,
} from '#/src/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/utils';

export const webAuthnApi = createApi({
    reducerPath: 'webAuthnApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        requestWebAuthnRegistration: build.mutation<any, WebAuthnRegChallengeCredentials>({
            queryFn: async (regChallengeBody, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                const challengeResult: any = await dispatch(
                    webAuthnApi.endpoints.requestWebAuthnRegChallenge.initiate(regChallengeBody),
                );

                if (challengeResult.error) {
                    dispatch(webAuthnErrorSet(errorDictionary.DEFAULT));
                    dispatch(webAuthnStatusSet(false));

                    return { error: challengeResult.error as FetchBaseQueryError };
                }

                const challengeResultData = challengeResult.data as any;

                const creationOptions = webauthnParseChallenge(
                    challengeResultData.publicKeyCredentialCreationOptions,
                    true,
                );

                const regResult = await navigator.credentials.create({
                    publicKey: creationOptions,
                });

                const credentials = webAuthnParseReg(regResult);

                const body = {
                    ...credentials,
                    requestId: challengeResultData.requestId,
                };

                const result = await fetchWithBQ({
                    url: Endpoint.WEB_AUTHN_REGISTRATION,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                        const errorResult = result.error.data as any;

                        dispatch(webAuthnStatusSet(errorResult.errors[0].message));

                        return { error: result.error as FetchBaseQueryError };
                }

                return { data: result.data };
            },
        }),
        requestWebAuthnRegChallenge: build.mutation<any, WebAuthnRegChallengeCredentials>({
            queryFn: async (body, _queryApi, _extraOptions, fetchWithBQ) => {
                const result = await fetchWithBQ({
                    url: Endpoint.WEB_AUTHN_REG_CHALLENGE,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    return { error: result.error };
                }

                return { data: result.data };
            },
        }),
        requestWebAuthnAuthorization: build.mutation<any, WebAuthnRegistrationCredentials>({
            queryFn: async (payload, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                await initFP(queryApi);

                const state = queryApi.getState() as ApplicationState;

                const browserId = getCookie(CookiesName.browserId);
                const fingerprint = selectFingerPrintCredentials(state);
                const newBrowserSecret = state?.Pincode?.newBrowserSecret;
                const newBrowserSecretEnc = state?.Pincode?.newBrowserSecretEnc;
                const browserSecret = window?.localStorage?.getItem(LocalStorageName.browserSecret);
                const browserSecretDate = window?.localStorage?.getItem(
                    LocalStorageName.browserSecretDate,
                );
                const clientId = selectClientId(state);

                const logMessage = checkPincodeParams(
                    browserId,
                    browserSecret,
                    browserSecretDate,
                    newBrowserSecretEnc,
                    clientId,
                );

                await clientInfoLog(logMessage);

                const body: WebAuthnAuthorizationCredentials = {
                    ...payload,
                    ...fingerprint,
                    new_browser_secret_enc: newBrowserSecretEnc,
                    browser_id: browserId,
                    browser_secret_date: browserSecretDate,
                };

                const result = await fetchWithBQ({
                    url: Endpoint.WEB_AUTHN_AUTHORIZATION,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;

                    dispatch(webAuthnStatusSet(false));
                    dispatch(webAuthnErrorSet(errorResult.errors[0].message));

                    return { error: result.error as FetchBaseQueryError };
                }

                if (newBrowserSecretEnc) {
                    setBrowserSecret(newBrowserSecret);
                }

                return { data: result.data };
            },
        }),
        requestWebAuthnChallenge: build.mutation<any, void>({
            queryFn: async (_body, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                const result = await fetchWithBQ({
                    url: Endpoint.WEB_AUTHN_CHALLANGE,
                    method: HttpMethod.POST,
                    body: {},
                });

                if (result.error) {
                    const errorResult = result.error.data as any;

                    dispatch(webAuthnStatusSet(false));
                    dispatch(webAuthnErrorSet(errorResult.errors[0].message));

                    return { error: result.error as FetchBaseQueryError };
                }

                const resultData = result.data as any;

                const params = webauthnParseChallenge(
                    JSON.parse(resultData.data.publicKeyCredentialRequestOptions),
                    false,
                );

                await webauthnAuthorization(
                    params,
                    (param: any) => {
                        const credentials = webAuthnParseAuth(param);

                        dispatch(
                            webAuthnApi.endpoints.requestWebAuthnAuthorization.initiate({
                                ...credentials,
                                requestId: resultData.data.requestId,
                            }),
                        );
                    },
                    () => {
                        dispatch(webAuthnErrorSet(errorDictionary.DEFAULT));
                    },
                );

                return { data: resultData };
            },
        }),
    }),
});
export const { useRequestWebAuthnRegistrationMutation, useRequestWebAuthnChallengeMutation } =
    webAuthnApi;
